import GameUI from "../../components/gameComponents/GameUI";
import Development from "./Development";

const GamePortfolio = () => {
    return <>
        <GameUI>
            <Development/>
        </GameUI>
    </>
};

export default GamePortfolio;