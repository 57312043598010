import Text from "./Text";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    margin-top: auto;
    padding-top: 20px;
    width: 100%;
    align-items: center;
    justify-content: center;
`

const Footer = () => {
    return <Container>
        <Text text={"©2023 | Desarrollado por Andrés Montes"}/>
    </Container>
}

export default Footer;