import styled from "styled-components"

const StyledButton = styled.button`
    display: flex;
    font-size: 1.2rem;
    margin: 10px auto;
    transition: all .3s;
    align-items: center;
    color: #454545;
    border: none;
    cursor: pointer;
    background: none;
    
    :hover{
        color: #000000;
        background: #C0C0C0;
        border-radius: 8px;
        transform: scale(1.1);
    }
`

const Button = ({text, func}) => {
    return <StyledButton onClick={func}>{text}</StyledButton>
};

export default Button;