import styled from "styled-components"

const StyledDiv = styled.button`
    width: 160px;
    height: 120px;
    background: #ffffff;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 15px;
    font-size: 1.2rem;
    font-weight: bold;
    margin: 10px;
    transition: all .3s;
    cursor: pointer;

    :hover{
        transform: scale(1.1);
        border: 1px solid #2b7be0;
    }

    ion-icon{
        font-size: 3rem;
        margin-bottom: 5px;
        color:#2b7be0;
    }

    @media screen and (max-width:400px){
        width: 40%;
    }
`

const SHBox = ({text, ico, func}) => {
    return <StyledDiv onClick={func}>
        {ico}
        {text}
    </StyledDiv>
};

export default SHBox;