import styled from "styled-components";
import Logo from "../img/favicon.svg"
import SubTitle from "../../components/SubTitle";
import Background from "../img/constellation.svg"

const Container = styled.div`
    width: 100%;
    height: 100%;
    background-image: url(${Background});
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const ContainerImg = styled.div`
    max-width: 50px;
    width: 100%;
    min-width: 100px;
    padding: 10px;
    border-radius: 50%;
    border: 2px solid transparent;
    margin-bottom: 10px;
    animation-name: load;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @keyframes load{
        0%{
            border-top: 2px solid #000000;
        }
        25%{
            border-top: 2px solid transparent;
            border-right: 2px solid #000000;
        }
        50%{
            border-right: 2px solid transparent;
            border-bottom: 2px solid #000000;
        }
        75%{
            border-bottom: 2px solid transparent;
            border-left: 2px solid #000000;
        }
        100%{
            border-left: 2px solid transparent;
            border-top: 2px solid #000000;
        }
    }
`

const Img = styled.img`
    width: 100%;
    background-color:#464646;
    border-radius: 50%;
    padding: 10px; 
    animation-name: loadImg;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @keyframes loadImg{
    0%{
        box-shadow: 2px 0 3px #000000;
    }
    5%{
        box-shadow: 0 2px 3px #000000;
    }
    10%{
        box-shadow: -2px 0 3px #000000;
    }
    15%{
        box-shadow: 0 -2px 3px #000000;
    }
    20%{
        box-shadow: 2px 0 3px #000000, 0 0 5px #000000;
    }
    40%{
        box-shadow: 0 0 20px #000000;  
    }
    100%{
        box-shadow: 0 0 3px #000000;
    }
    }
`

const Development = () => {
    return <Container>
        <ContainerImg>
            <Img src={Logo}/>
        </ContainerImg>
        <SubTitle text={"En Desarrollo"}/>
    </Container>
};

export default Development;