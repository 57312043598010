import styled from "styled-components";
import Background from "../img/constellation.svg"
import Title from "../../components/Title";
import SubTitle from "../../components/SubTitle";
import { Link } from "react-router-dom";
import Linkage from "../../components/Linkage";

const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-image: url(${Background});

    ion-icon{
        font-size: 7rem;
    }

    a{
        font-size: 2rem;

        h2, ion-icon{
            font-weight: bold;
            font-size: 2rem;
            margin-bottom: 0;
        }

        :hover{
            border: 2px solid #2b7be0;
            background: #ffffff;
            
            h2, ion-icon{
                margin-bottom: 0;
                color: #2b7be0
            }
        }
    }
`

const Error404 = () => {
    return <Container>
        <ion-icon name="sad-outline"></ion-icon>
        <Title text={"ERROR 404"}/>
        <SubTitle text={"¡Ooops! La pagina que estas buscando no existe."}/>
        <Linkage to={"/"} text={<SubTitle text={"Ir al inicio"}/>} ico={<ion-icon name="home-outline"></ion-icon>}/>
    </Container>
};

export default Error404;