import styled from "styled-components";
import SubTitle from "../../components/SubTitle";
import LogoAlura from "../img/alura_logo.png";
import LogoSena from "../img/sena_logo.png";
import LogoUdemy from "../img/udemy_logo.png";
import LogoUninorte from "../img/uninorte_logo.png";

const Container = styled.div`
    background: #EAF2FD;
    padding: 20px;
    margin-top: 20px;
`

const EducationContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
`

const EducationBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 150px;
    background: #ffffff;
    padding: 10px;
    margin-bottom: 5px;
    transition: all .3s;

    :hover{
        transform: scale(1.05);
    }
`

const Img = styled.img`
    max-height: 90%;
`

const Title = styled.h3`
    font-size: 1.2rem;
`

const Text = styled.p`
    font-size: 1rem;
`

const Education = () => {

    const educationList = [
        {
            logo: LogoSena,
            title: "Análisis y desarrollo de sistemas de información",
            year:  "2013 - SENA"
        },
        {
            logo: LogoUninorte,
            title: "Programación con énfasis en Aplicaciones Móviles",
            year:  "2021 - Uninorte - MisionTic 2022"
        },
        {
            logo: LogoUdemy,
            title: "Curso nivel intermedio en HTML, CSS, JavaScript",
            year:  "2022 - Udemy"
        },
        {
            logo: LogoAlura,
            title: "Especializacion en programacion front-end React",
            year:  "Cursando - Alura Latam"
        }
    ];

    return <Container>
        <SubTitle text={"Formación Académica"}/>
        <EducationContainer>
            {educationList.map((education, i) => {
                const {logo, title, year} = education;

                return <Container key={i}>
                    <EducationBox>
                        <Img src={logo} alt="Education image"/>
                    </EducationBox>
                    <Title>{title}</Title>
                    <Text>{year}</Text>
                </Container>
            })}
        </EducationContainer>
    </Container>
};

export default Education;