import styled from "styled-components";
import SubTitle from "../../components/SubTitle";
import { useState } from "react";
import SHBox from "../../components/SHBox";
import DetailsBox from "../../components/DetailsBox";

const Container = styled.div`
    width: 100%;
    background: #EAF2FD;
    padding: 20px;
    margin-top: 20px;
`

const ContainerSkills = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`

const Skills = () => {
    const [skill, setSkill] = useState();

    const skills = [{text: "HTML5", ico: <ion-icon name="logo-html5"></ion-icon>},
    {text: "JavaScript", ico: <ion-icon name="logo-javascript"></ion-icon>},
    {text: "CSS3", ico:  <ion-icon name="logo-css3"></ion-icon>},
    {text: "Git", ico: <ion-icon name="logo-github"></ion-icon>},
    {text: "React", ico: <ion-icon name="logo-react"></ion-icon>},
    {text: "SQL", ico: <ion-icon name="server-outline"></ion-icon>},
    {text: "Firebase", ico: <ion-icon name="logo-firebase"></ion-icon>}];

    const skillUpdate = (i) => {
        setSkill(skills[i]);
    };

    return <Container>
        <SubTitle text={"Skills"}/>
        <ContainerSkills>
            {skills.map((skill, i) => {
                const {text, ico} = skill;
                return <SHBox key={i} text={text} ico={ico} func={()=>skillUpdate(i)}/>
            })}
        </ContainerSkills>
        {/* <DetailsBox skill={skill}/> */}
    </Container>
};

export default Skills;