import styled from "styled-components";

const StyledH2 = styled.h2`
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
`

const SubTitle = ({text}) => {
    return <StyledH2>{text}</StyledH2>
};

export default SubTitle;