import styled from "styled-components"
import SubTitle from "../../components/SubTitle"
import Text from "../../components/Text";
import { useState } from "react";


const Container = styled.div`
    background: #EAF2FD;
    padding: 20px;
    margin-top: 20px;
`

const Form = styled.form`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
    margin: auto;
`

const Input = styled.input`
    width: 100%;
    max-width: 500px;
    font-size: 1rem;
    border: none;
    padding: 10px 15px;
    margin: 10px auto;
    border-radius: 8px;
    transition: all .3s;

    :focus{
        outline: 2px solid #2b7be0;
    }
`

const TextArea = styled.textarea`
    width: 100%;
    max-width: 500px;
    font-size: 1rem;
    border: none;
    padding: 10px 15px;
    margin: 10px auto;
    border-radius: 8px;
    transition: all .3s;

    :focus{
        outline: 2px solid #2b7be0;
    }
`

const Button = styled.button`
    width: 100%;
    max-width: 300px;
    padding: 10px 15px;
    font-size: 1rem;
    margin-top: 10px;
    border-radius: 8px;
    border: 1px solid #2b7be0;
    color: #2b7be0;
    transition: all .3s;
    cursor: pointer;

    :hover{
        background: #2b7be0;
        color: #ffffff;
        border: 1px solid #ffffff;
    }
`

const Contact = () => {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [subjet, setSubjet] = useState("");
    const [message, setMessage] = useState("");

    const submitForm = (e) => {
        e.preventDefault();
        const cuerpoCorreo = 
        `Nombre: ${name}%0D%0ATelefono: ${phone}%0D%0AMensaje: ${message}%0D%0A`;
        const linkCorreo = `mailto:hypnoz@live.com?subject=${subjet}&body=${cuerpoCorreo}`;
        window.location.href = linkCorreo;       
    }

    return <Container>
        <SubTitle text={"Contacto"}/>
        <Text text={"¿Quieres contactarme?"}/>
        <Text text={"Completa el siguiente formulario y me pondré en contacto con usted lo antes posible. "}/>
        <Form onSubmit={(e)=>{submitForm(e)}}>
            <Input 
                type="text" 
                name="nombre"
                placeholder="Nombre"
                required
                value={name}
                onChange={(e)=>{setName(e.target.value)}}/>
            <Input 
                type="number" 
                name="phone"
                placeholder="Telefono"
                required
                value={phone}
                onChange={(e)=>{setPhone(e.target.value)}}/>
            <Input 
                type="text" 
                name="subjet"
                placeholder="Asunto"
                required
                value={subjet}
                onChange={(e)=>{setSubjet(e.target.value)}}/>
            <TextArea
                rows={5}
                type="text" 
                name="message"
                placeholder="Mensaje"
                required
                value={message}
                onChange={(e)=>{setMessage(e.target.value)}}/>
            <Button>Enviar</Button>
        </Form>
    </Container>
};

export default Contact;