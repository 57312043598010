import styled from "styled-components";

const StyledDiv = styled.div`
    width: 100%;
    padding: 10px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 900px){
        padding: 10px 20px;
    }

    @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: start;
    }

    @media screen and (max-width: 500px){
        padding: 10px 10px;
    }
`

const RowContainer = ({children}) => {
    return <StyledDiv>
        {children.map((children, i) => children)}
    </StyledDiv>
}

export default RowContainer;