import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GlobalStyle from "./GlobalStyle";
import MainScreen from './assets/pages/MainScreen';
import GamePortfolio from "./assets/pages/GamePortfolio";
import NormalPortfolio from "./assets/pages/NormalPortfolio";
import Error404 from "./assets/pages/Error404";


function App() {
  return (
    <Router>
      <GlobalStyle/>
      <Routes>
        <Route
          path='/'
          element={<NormalPortfolio/>}
        />
        <Route
          path="/game"
          element={<GamePortfolio/>}
        />
        <Route
          path="/select"
          element={<MainScreen/>}
        />
        <Route
          path="/development"
          element={<GamePortfolio/>}
        />
        <Route
          path="*"
          element= {<Error404/>}
        />
      </Routes>
    </Router>
  );
}

export default App;
