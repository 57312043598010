import styled from "styled-components";
import SubTitle from "../../components/SubTitle";
import { useState } from "react";
import SHBox from "../../components/SHBox";
import DetailsBox from "../../components/DetailsBox";

const Container = styled.div`
    width: 100%;
    background: #EAF2FD;
    padding: 20px;
    margin-top: 20px;
`

const ContainerSkills = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`

const Hobbies = () => {
    const [hobbie, setHobbie] = useState();

    const hobbies = [{text: "Videojuegos", ico: <ion-icon name="game-controller-outline"></ion-icon>},
    {text: "GYM", ico: <ion-icon name="fitness-outline"></ion-icon>},
    {text: "Musica", ico:  <ion-icon name="musical-notes-outline"></ion-icon>},
    {text: "Series", ico: <ion-icon name="tv-outline"></ion-icon>},
    {text: "Anime", ico: <ion-icon name="happy-outline"></ion-icon>},
    {text: "Fotografia", ico: <ion-icon name="camera-outline"></ion-icon>},
    {text: "Viajes", ico: <ion-icon name="leaf-outline"></ion-icon>}];

    const skillUpdate = (i) => {
        setHobbie(hobbies[i]);
    };

    return <Container>
        <SubTitle text={"Hobbies"}/>
        <ContainerSkills>
            {hobbies.map((hobbie, i) => {
                const {text, ico} = hobbie;
                return <SHBox key={i} text={text} ico={ico} func={()=>skillUpdate(i)}/>
            })}
        </ContainerSkills>
        {/* <DetailsBox skill={hobbie}/> */}
    </Container>
};

export default Hobbies;