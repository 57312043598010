import styled from "styled-components";
import SubTitle from "./SubTitle";
import Text from "./Text";

const Container = styled.div`
    margin-top: 50px;

`

const ContainerHead = styled.div`
    display: flex;
    align-items: center;
    font-size: 2.5rem;
    color:#2b7be0;

    ion-icon{
        margin-right: 5px;
    }

    h2{
        margin-bottom: 0
    }
`

const DetailsBox = ({skill}) => {

    if(!skill){
        return <></>
    } else {
        const {text, ico} = skill;

        return <Container>
            <ContainerHead>
                {ico}
                <SubTitle text={text}/>
            </ContainerHead>
            <Text text={"Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur, impedit est? Maxime assumenda fuga ipsam provident laudantium similique, suscipit eligendi sit hic. Ipsum, esse reiciendis? Assumenda itaque soluta optio, fuga, ipsa consequuntur natus in impedit cum ex quae. Beatae labore magnam id rerum facilis odio voluptatem perferendis dolorem. Dolores impedit blanditiis possimus, enim adipisci optio modi eligendi voluptatibus deserunt perferendis quis quaerat, ea quia sunt neque aliquam. Consectetur fuga illo dolore maiores rerum dolor pariatur, aspernatur doloribus, asperiores sint odio quam sit nostrum similique vero laudantium sequi aperiam reiciendis? Tenetur ratione modi alias consectetur repudiandae accusantium recusandae quam inventore quaerat!"}/>
        </Container>
    } 
};

export default DetailsBox;