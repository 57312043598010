import styled from "styled-components";

const StyledDiv = styled.div`
    width: 100%;
    max-width: 1400px;
    margin: auto;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const Container = ({children}) => {
    return <StyledDiv>
        {children.map((children, i) => children)}
    </StyledDiv>
}

export default Container;