import styled from "styled-components";
import { Link } from "react-router-dom";
import DropdownMenu from "../components/DropdownMenu";
import Menu from "../components/Menu";
import Button from "../components/Button";
import Logo from "../assets/img/favicon.svg"
import { useState } from "react";

const Container = styled.div`
    width: 100%;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
`

const Box = styled.div`
    display: flex;
    align-items: center;

    a{
        display: flex;
        align-items: center;
    }
`

const Img = styled.img`
    width: 30px;
    margin-right: 5px;
    background: #464646;
    border-radius: 50%;
    padding: 4px;
`

const Name = styled.h3`
    font-size: 1.5rem;
    font-weight: bold;
`

const DropOptions = styled.div`
    transition: all .3s;
`

const Header = ({menuFunc}) => {
    const [dropMenu, setDropMenu] = useState(false);

    const uptadeDropMenu = (i) => {
        menuFunc(i);
        setDropMenu(false);
    }

    return <Container>
        <Box>
            <Link to={"/"}>
                <Img alt="Logo" src={Logo}/>
                <Name>Andrés Montes</Name>
            </Link>
        </Box>
        <DropdownMenu>
            <Button text={<ion-icon name="menu"></ion-icon>} func={() => setDropMenu(!dropMenu)}/>
            {dropMenu && 
                <DropOptions>
                    <Button text={"Sobre mi"} func={()=>uptadeDropMenu(0)}/>
                    <Button text={"Skills"} func={()=>uptadeDropMenu(1)}/>
                    <Button text={"Hobbies"} func={()=>uptadeDropMenu(2)}/>
                    <Button text={"Formacion"} func={()=>uptadeDropMenu(3)}/>
                    <Button text={"Proyectos"} func={()=>uptadeDropMenu(4)}/>
                    <Button text={"hypnoz@live.com"} func={()=>uptadeDropMenu(5)}/>
                </DropOptions>}
            
        </DropdownMenu>
        <Menu>
            <Button text={"Sobre mi"} func={()=>menuFunc(0)}/>
            <Button text={"Skills"} func={()=>menuFunc(1)}/>
            <Button text={"Hobbies"} func={()=>menuFunc(2)}/>
            <Button text={"Formacion"} func={()=>menuFunc(3)}/>
            <Button text={"Proyectos"} func={()=>menuFunc(4)}/>
            <Button text={"hypnoz@live.com"} func={()=>menuFunc(5)}/>
        </Menu> 
    </Container>
};

export default Header;