import styled from "styled-components"

const StyledUl = styled.ul`
    width: 50%;
    display: flex;
    justify-content: space-around;
    transition: all 0.3s;

    @media screen and (max-width: 1200px){
        width: 60%;
    }

    @media screen and (max-width: 900px){
        width: 70%;
    }

    @media screen and (max-width: 800px){
        display: none;
    }
`

const Menu = ({children}) => {
    return <StyledUl>
        {children.map((children) => children)}
    </StyledUl>
};

export default Menu;