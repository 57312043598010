import styled from "styled-components";

const StyledH1 = styled.h1`
    font-size: 3rem;
    font-weight: bold;
`

const Title = ({text}) => {
    return <StyledH1>{text}</StyledH1>
};

export default Title;