import styled from "styled-components";
import SubTitle from "../../components/SubTitle";
import Text from "../../components/Text";
import Signature from "../img/signature.png";

const Container = styled.div`
    width: 100%;
    background: #EAF2FD;
    padding: 20px 40px;
    margin-top: 20px;

    @media screen and (max-width: 500px){
        padding: 20px;
    }
`

const Img = styled.img`
    width: 120px;
    margin-top: 10px;
`

const About = () => {
    return <Container>
        <SubTitle text={"Sobre mi"}/>
        <Text text={"Me llamo Wilson Andrés Montes, soy un programador front-end Colombiano, siempre me ha apasionado la tecnología y tengo muchos años de experiencia en el área."}/>
        <Text text={"Siempre me mantengo en constante aprendizaje para estar al día con los avances tecnológicos y mejorar mis habilidades, me considero una persona dedicada y creativa a la hora de resolver nuevos proyectos y desafíos que se me presenten. "}/>
        <Img src={Signature} alt="firma"/>
    </Container>
};

export default About;