import styled from "styled-components";
import Text from "../Text";
import Controls from "./Controls";

const Console = styled.div`
    width: 100%;
    height: 100vh;
    background: #7209B7;
    box-shadow: inset 0px 0px 5px 3px rgba(0,0,0,0.25);
`

const Container = styled.div`
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-width: 1400px;  
`

const ContainerBattery = styled.div`
    height: 40px;
`

const Battery = styled.div`
    display: flex;
    float: right;
    margin-right: 15%;

    P{  
        margin: auto;
        font-size: 15px;
        font-weight: bold;
        color: #000000;
        text-shadow: 0.5px 0.5px 2px rgba(255,255,255,0.5);
    }
`

const BatteryLed = styled.div`
    width: 10px;
    height: 25px;
    background: #04BB00;
    margin-right: 5px;
    border-radius: 0 0 8px 8px;
    box-shadow: 0px 0px 4px 2px rgba(81,245,78,0.7);
`

const DisplayBorder = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    background: #000000;
    margin: 0 50px;
    width: calc(100% -100px);
    height: calc(100% - 260px);
    border-radius: 10px;

    @media screen and (max-width: 750px) {
        margin: 0 auto;
        width: calc(100% - 50px);
        height: calc(100% - 210px);
    }

    @media screen and (max-width: 750px) {
        width: calc(100% - 10px);
    }
`

const ContainerBorderBtns = styled.div`
    display: none;
    align-self: center;
    position: absolute;
    bottom: 5px;
    justify-content: space-around;

    @media screen and (max-width: 600px){
        display: flex;
    };
`

const BorderBtn = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    color: #000000;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #555555;
    margin: 7.5px;
    box-shadow: 4px 4px 4px 4px rgba(0,0,0, 0.25),
    0px 0px 1px 2px rgba(0,0,0,0.5),
    inset -1px -1px 2px 2px rgba(0,0,0,0.25);
    cursor: pointer; 
`

const BorderBtnSS = styled(BorderBtn)`
    margin-left: 50px;
`

const GameDisplay = styled.div`
    background: #ffffff;
    margin: 25px 25px 50px 25px;
    width: calc(100% - 50px);
    height: calc(100% - 75px);
    box-shadow: inset 0px 0px 10px 5px rgba(0,0,0, 0.25);
`


const GameUI = ({children}) => {
    return <Console>
        <Container>
            <ContainerBattery>
                <Battery>
                    <BatteryLed/>
                    <BatteryLed/>
                    <BatteryLed/>
                    <BatteryLed/>
                    <BatteryLed/>
                    <Text text={"BATTERY"}/>
                </Battery>
            </ContainerBattery>
            <DisplayBorder>
                <GameDisplay>
                    {children}
                </GameDisplay>
                <ContainerBorderBtns>
                    <BorderBtn href="https://www.linkedin.com/in/wamz/" target="_blank" rel="noopener noreferrer"><ion-icon name="logo-linkedin"></ion-icon></BorderBtn>
                    <BorderBtn href="https://github.com/xHypnos" target="_blank" rel="noopener noreferrer"><ion-icon name="logo-github"></ion-icon></BorderBtn>
                    <BorderBtn href="https://www.instagram.com/hypnos_a/" target="_blank" rel="noopener noreferrer"><ion-icon name="logo-instagram"></ion-icon></BorderBtn>
                    <BorderBtn href="https://www.tiktok.com/@hypnos_a" target="_blank" rel="noopener noreferrer"><ion-icon name="logo-tiktok"></ion-icon></BorderBtn>
                    
                    <BorderBtnSS><ion-icon name="stop-outline"></ion-icon></BorderBtnSS>
                    <BorderBtn><ion-icon name="play-outline"></ion-icon></BorderBtn>
                </ContainerBorderBtns>
            </DisplayBorder>
            <Controls/>
        </Container>
    </Console>
};

export default GameUI;