import Container from "../../components/Container";
import RowContainer from "../../components/RowContainer";
import Linkage from "../../components/Linkage";
import styled from "styled-components";
import SubTitle from "../../components/SubTitle";
import Text from "../../components/Text";
import Profile from "../img/profile.png"
import About from "./About";
import { useState } from "react";
import Skills from "./Skills";
import Hobbies from "./Hobbies";
import Education from "./Education";
import Projects from "./Projects";
import Contact from "./Contact";
import Footer from "../../components/Footer";
import SocialMenu from "../../components/SocialMenu";
import Header from "../../components/Header";

const ContainerPortfolio = styled.div`
    width: auto;
    max-width: 1400px;
    display: flex;
    margin: auto;
    flex-direction: column;
    height: 100vh;
`

const Img = styled.img`
    width: 30px;
    margin-right: 5px;
    background: #464646;
    border-radius: 50%;
    padding: 4px;
`

const ProfileImg = styled(Img)`
    width: 300px;
    transition: all .3s;

    @media screen and (max-width: 900px){
        width: 150px;
    }

    @media screen and (max-width: 500px){
        width: 100px;
        margin: 5px 10px;
    }
`

const NormalPortfolio = () => {
    const [menu, setMenu] = useState(<About/>);

    const menuOptions = [
        <About/>,
        <Skills/>,
        <Hobbies/>,
        <Education/>,
        <Projects/>,
        <Contact/>       
    ];

    const menuUpdate = (i) =>{
        setMenu(menuOptions[i]);
    }

    return <ContainerPortfolio>
            <Header menuFunc = {menuUpdate}/>
            <RowContainer>
                <ProfileImg src={Profile} alt="Profile Img"/>
                <Container>
                    <SubTitle text={"Hola, mi nombre es Andrés y construyo páginas web"}/>
                    <Text text={"Soy técnico en análisis y desarrollo, realicé un diplomado en programación en la Universidad del Norte y actualmente estoy participando del proyecto Oracle ONE en Alura Latam."}/>
                    <SocialMenu>
                        <Linkage 
                        target="_blank"
                        ico={<ion-icon name="logo-github"></ion-icon>}
                        to={"https://github.com/xHypnos"} 
                        text={"Github"}/>
                        <Linkage
                        target="_blank" 
                        ico={<ion-icon name="logo-linkedin"></ion-icon>}
                        to={"https://www.linkedin.com/in/wamz"} 
                        text={"Linkedin"}/>
                        <Linkage
                        target="_blank" 
                        ico={<ion-icon name="logo-instagram"></ion-icon>}
                        to={"https://www.instagram.com/hypnos_a"} 
                        text={"Instagram"}/>                                  
                    </SocialMenu>
                </Container>
            </RowContainer>
            {menu}
            <Footer/>
    </ContainerPortfolio>
};

export default NormalPortfolio;