import styled from "styled-components"

const Container = styled.div`
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
`

const LeftControls = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: #8810D7;
    box-shadow: inset 0px 0px 10px 2px #52127C;

    @media screen and (max-width: 750px) {
        width: 150px;
        height: 150px;
    }
`

const Cross = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    text-shadow: 0.5px 0.5px 2px rgba(255,255,255,0.5);
    width: 50px;
    height: 50px;
    background: #4895EF;
    cursor: pointer; 

    @media screen and (max-width: 750px) {
        width: 40px;
        height: 40px;
    }
`

const UpCross = styled(Cross)`
    position: absolute;
    top: 25px;
    border-radius: 10px 10px 0 0;
    box-shadow: 4px 4px 4px 4px rgba(0,0,0, 0.25),
    0px 0px 1px 2px rgba(0,0,0,0.5),
    inset -1px -1px 2px 2px rgba(0,0,0,0.25),
    inset 1px 1px 3px 2px rgba(255,255,255,0.3) ;

    @media screen and (max-width: 750px) {
        top: 15px;
    }
`

const RightCross = styled(Cross)`
    position: absolute;
    right: 25px;
    border-radius: 0 10px 10px 0;
    box-shadow: 4px 4px 4px 4px rgba(0,0,0, 0.25),
    0px 0px 1px 2px rgba(0,0,0,0.5),
    inset -1px -1px 2px 2px rgba(0,0,0,0.25),
    inset 1px 1px 3px 2px rgba(255,255,255,0.3) ;

    @media screen and (max-width: 750px) {
        right: 15px;
    }
`

const CenterCross = styled(Cross)`
    z-index: 2; 
`

const DownCross = styled(Cross)`
    z-index: 1;
    position: absolute;
    bottom: 25px;
    border-radius: 0 0 10px 10px;
    box-shadow: 4px 4px 4px 4px rgba(0,0,0, 0.25),
    0px 0px 1px 2px rgba(0,0,0,0.5),
    inset -1px -1px 2px 2px rgba(0,0,0,0.25),
    inset 1px 1px 3px 2px rgba(255,255,255,0.3) ;

    @media screen and (max-width: 750px) {
        bottom: 15px;
    }
`

const LeftCross = styled(Cross)`
    position: absolute;
    left: 25px;
    border-radius: 10px 0 0 10px;
    box-shadow: 4px 4px 4px 4px rgba(0,0,0, 0.25),
    0px 0px 1px 2px rgba(0,0,0,0.5),
    inset -1px -1px 2px 2px rgba(0,0,0,0.25),
    inset 1px 1px 3px 2px rgba(255,255,255,0.3) ;

    @media screen and (max-width: 750px) {
        left: 15px;
    }
`

const CenterControls = styled.div`
    display: block;

    @media screen and (max-width: 600px) {
        display: none;
    }
`

const ContainerCCBlock = styled.div`
    display: block;
`

const ContainerCC = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
`

const ContainerCCB = styled.div`
    display: flex;
    margin: 5px;
    border-radius: 18px;
    background: #8810D7;
    box-shadow: inset 0px 0px 10px 2px #52127C; 
`

const SSList = styled.ul`
    display: flex;
    padding: 6px;  
`

const SSBtn = styled.li`
    width: 65px;
    text-align: center;
    background: #4895EF;
    border-radius: 10px;
    font-weight: bold;
    padding: 5px;
    margin: 6px;
    box-shadow: 4px 4px 4px 4px rgba(0,0,0, 0.25),
    0px 0px 1px 2px rgba(0,0,0,0.5),
    inset -1px -1px 2px 2px rgba(0,0,0,0.25),
    inset 1px 1px 3px 2px rgba(255,255,255,0.3) ;
    text-shadow: 0.5px 0.5px 2px rgba(255,255,255,0.5);
    cursor: pointer;   

    @media screen and (max-width: 750px) {
        width: 60px;
    }
`

const SocialBtn = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    color: #000000;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #4895EF;
    margin: 7.5px;
    box-shadow: 4px 4px 4px 4px rgba(0,0,0, 0.25),
    0px 0px 1px 2px rgba(0,0,0,0.5),
    inset -1px -1px 2px 2px rgba(0,0,0,0.25),
    inset 1px 1px 3px 2px rgba(255,255,255,0.3) ;
    text-shadow: 0.5px 0.5px 2px rgba(255,255,255,0.5);
    cursor: pointer; 

    @media screen and (max-width: 750px) {
        width: 30px;
        height: 30px;
    }
`

const RigthControls = styled.div`
    position: relative;
`

const ControlsRightUl = styled.ul`
    margin: 5px;
    padding: 10px;
    border-radius: 50px;
    background: #8810D7;
    box-shadow: inset 0px 0px 10px 2px #52127C;
    transform: rotate(-45deg)
`

const CRBtn = styled.li`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #4895EF;
    margin: 7.5px;
    box-shadow: 4px 4px 4px 4px rgba(0,0,0, 0.25),
    0px 0px 1px 2px rgba(0,0,0,0.5),
    inset -1px -1px 2px 2px rgba(0,0,0,0.25),
    inset 1px 1px 3px 2px rgba(255,255,255,0.3) ;
    text-shadow: 0.5px 0.5px 2px rgba(255,255,255,0.5); 
    transform: rotate(45deg);
    cursor: pointer;

    @media screen and (max-width: 750px) {
        width: 45px;
        height: 45px;
    }
`

const Controls = () => {
    return <Container>
        <LeftControls>
            <UpCross>↑</UpCross>
            <RightCross>→</RightCross>
            <CenterCross></CenterCross>
            <DownCross>↓</DownCross>
            <LeftCross>←</LeftCross>
        </LeftControls>
        <CenterControls>
            <ContainerCCBlock>
                <ContainerCC>
                    <ContainerCCB>
                        <SSList>
                            <SSBtn>SELECT</SSBtn>
                            <SSBtn>START</SSBtn>
                        </SSList>
                    </ContainerCCB>
                </ContainerCC>
                <ContainerCC>
                    <ContainerCCB>
                        <SSList>
                            <SocialBtn href="https://www.linkedin.com/in/wamz/" target="_blank" rel="noopener noreferrer"><ion-icon name="logo-linkedin"></ion-icon></SocialBtn>
                            <SocialBtn href="https://github.com/xHypnos" target="_blank" rel="noopener noreferrer"><ion-icon name="logo-github"></ion-icon></SocialBtn>
                            <SocialBtn href="https://www.instagram.com/hypnos_a/" target="_blank" rel="noopener noreferrer"><ion-icon name="logo-instagram"></ion-icon></SocialBtn>
                            <SocialBtn href="https://www.tiktok.com/@hypnos_a" target="_blank" rel="noopener noreferrer"><ion-icon name="logo-tiktok"></ion-icon></SocialBtn>
                        </SSList>
                    </ContainerCCB>
                </ContainerCC>
            </ContainerCCBlock>
        </CenterControls>
        <RigthControls>
            <ControlsRightUl>
                <CRBtn>A</CRBtn>
                <CRBtn>B</CRBtn>
            </ControlsRightUl>
        </RigthControls>
    </Container> 
};

export default Controls;