import SubTitle from "../../components/SubTitle";
import Text from "../../components/Text";
import EncripImg from "../img/encriptador_img.webp";
import GeekImg from "../img/alurageek.webp";
import OrgImg from "../img/org.webp";
import AnimeflixImg from "../img/animeflix.webp";
import styled from "styled-components";
import Linkage from "../../components/Linkage";
import RycImg from "../img/ryc.webp";

const ProjectsContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    transition: all .3s;

    h2, p{
        margin: 5px;
    }
`

const Container = styled.div`
    background: #EAF2FD;
    padding: 20px;
    margin-top: 20px;
`

const ProjectContainer = styled(Container)`
    padding: 10px 20px;
    margin-top: 10px;
`

const Img = styled.img`
    width: auto;
    border: 2px solid #2b7be0;
    max-width: 500px;
    aspect-ratio: 3/2;
    object-fit: cover;
    transition: all .3s;

    @media screen and (max-width: 600px) {
        width: 100%;
    }
`

const ContainerBtns = styled.div`
    display: flex;

    a{
        margin: 5px 5px 0;
        border: 1px solid #2b7be0;
        padding: 10px 20px;
        color: #2b7be0;

        :hover{
            border: none;
            background: #2b7be0;
            color: #ffffff;
        }
    }
`

const Projects = () => {

    const experienceList = [
        {
            name: "Encriptador de texto",
            type: "Challenge Alura",
            img: EncripImg,
            repo: "https://github.com/xHypnos/encriptador-texto",
            demo: "https://xhypnos.github.io/encriptador-texto/",
            use: ["JS", "CSS", "HTML"]
        },
        {
            name: "eCommerce - AluraGeek",
            type: "Challenge Alura",
            img: GeekImg,
            repo: "https://github.com/xHypnos/alurageek",
            demo: "https://alurageek-tan.vercel.app/",
            use: ["JS", "CSS", "HTML"]
        },
        {
            name: "Organizador de Equipos",
            type: "Challenge Alura",
            img: OrgImg,
            repo: "https://github.com/xHypnos/org",
            demo: "https://org-omega.vercel.app/",
            use: ["JS", "CSS", "HTML", "React"]
        },
        {
            name: "Animeflix",
            type: "Challenge Alura",
            img: AnimeflixImg,
            repo: "https://github.com/xHypnos/animeflix",
            demo: "https://animeflix-xhypnos.vercel.app/",
            use: ["React", "Firebase", "Styled-components"]
        },
        {
            name: "R&C Computadores",
            type: "Proyecto Personal",
            img: RycImg,
            repo: "https://github.com/xHypnos",
            demo: "https://ryc.vercel.app/",
            use: ["React", "Styled-components"]
        }
    ]

    return <Container>
        <SubTitle text={"Experiencia Profesional"}/>
        <ProjectsContainer>
            {experienceList.map((experience, i) => {
                const {name, type, img, repo, demo, use} = experience;
                return <ProjectContainer key={i}>
                    <SubTitle text={name}/>
                    <Img src={img}/>
                    <Text text={type}/>
                    <ContainerBtns>
                        <Linkage text={"Ver Demo"} target={"_blank"} to={demo}/>
                        <Linkage text={"Repositorio"} target={"_blank"} to={repo}/>
                    </ContainerBtns> 
                </ProjectContainer>
            })}
        </ProjectsContainer>
    </Container>
};

export default Projects;