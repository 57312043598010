import styled from "styled-components"

const StyledP = styled.p`
    font-size: 1.2rem;
    font-weight: 400;
    margin: 10px 0;
`

const Text = ({text}) => {
    return <StyledP>
        {text}
    </StyledP>
};

export default Text;