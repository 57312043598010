import styled from "styled-components"

const StyledUl = styled.ul`
    width: 330px;
    display: flex;
    justify-content: space-between;
    transition: all 0.3s;
`

const SocialMenu = ({children}) => {
    return <StyledUl>
        {children.map((children) => children)}
    </StyledUl>
};

export default SocialMenu;