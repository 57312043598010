import styled from "styled-components";

const Container = styled.div`
    display: none;
    position: absolute;
    right: 5px;
    top: 5px;
    transition: all .3s;
    background: #fff;
    z-index: 1;

    ion-icon{
        font-size: 1.5rem;
        transition: all .3s;
    }

   

    @media screen and (max-width: 800px){
        display: block;
    }
`

const DropdownMenu = ({children}) => {
    return <Container>
        {children.map((children) => children)}
    </Container>
};

export default DropdownMenu;