import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled(Link)`
    display: flex;
    font-size: 1.2rem;
    margin: 10px auto;
    transition: all .3s;
    align-items: center;
    padding: 2px 5px;

    ion-icon{
        margin-right: 5px;
        font-size: 1.2rem;
    }

    :hover{
        color: #000000;
        background: #C0C0C0;
        border-radius: 8px;
        transform: scale(1.1);
    }
`

const Linkage = ({to, text, ico, target}) => {
    if(target === "_blank"){
        return <StyledLink to={to} target={target} rel="noopener noreferrer">
            {ico}
            {text}
        </StyledLink>
    }else{
        return <StyledLink to={to}>
            {ico}
            {text}
        </StyledLink>
    }
}

export default Linkage;